import axios, { AxiosResponse } from 'axios';
import { baseURL, frontendURL } from './Config';

interface AxiosData {
  data: any;
}

export async function get(endPoint: string) {
  try {
    const res = await axios.get(`${baseURL}/${endPoint}`, { withCredentials: true });
    return res?.data;
  } catch (error: any) {
    if (error?.response?.status === 401 && window.location.pathname !== '/login') {
      window.location.replace(`${frontendURL}/login`);
    }
    throw new Error(error.statusText);
  }
}

export async function post(endPoint: string, body?: any): Promise<AxiosResponse<AxiosData | any>> {
  try {
    const res = await axios.post<AxiosData>(`${baseURL}/${endPoint}`, body, { withCredentials: true });
    return res;
  } catch (error: any) {
    throw new Error(error.statusText);
  }
}

export async function put(endPoint: string, body?: any): Promise<AxiosResponse<AxiosData>> {
  try {
    const res = await axios.put<AxiosData>(`${baseURL}/${endPoint}`, body, { withCredentials: true });
    return res;
  } catch (error: any) {
    throw new Error(error.statusText);
  }
}

export async function del(endPoint: string, uuid: string): Promise<AxiosResponse<AxiosData>> {
  try {
    const res = await axios.delete<AxiosData>(`${baseURL}/${endPoint}/${uuid}`, { withCredentials: true });
    return res;
  } catch (error: any) {
    throw new Error(error.statusText);
  }
}

export const login = async (email: string, password: string) => {
  const data = { email: email.toLowerCase(), password };
  return axios.post(`${baseURL}/auth/login`, data, { withCredentials: true });
};
