import React, { useState, useEffect } from 'react';
import { post } from '../../../helpers/BackendService';
import styles from './Checkout.module.css';
import { usePageContext } from '../../../Context';

export default function SmartumSuccess() {
  const [message, setMessage] = useState<string>();
  const [emailText, setEmailText] = useState<string | undefined>();
  const queryParameters = new URLSearchParams(window.location.search);
  const jwt = queryParameters.get('jwt');
  const { getTag, setLoading, locale } = usePageContext();

  useEffect(() => {
    checkPayment();
  }, [getTag]);

  const checkPayment = async () => {
    setLoading(true);
    try {
      const res = await post(`smartum?jwt=${jwt}&locale=${locale}`);
      switch (res.status) {
      case 200:
        setMessage(getTag('paymentSucceded'));
        setEmailText(getTag('emailText'));
        localStorage.removeItem('cart');
        setLoading(false);
        break;
      case 500:
        setMessage(getTag('somethingWentWrong'));
        setLoading(false);
        break;
      default:
        setMessage(getTag('somethingWentWrong'));
        setLoading(false);
        break;
      }
    } catch (error) {
      setMessage(getTag('somethingwentwrong'));
      setLoading(false);
    }
  };

  return (
    <div className={styles.statusWrapper}>
      <div className={styles.cardBox}>
        <h2>{message}</h2>
        <h3>{emailText}</h3>
      </div>
    </div>
  );
}
