import {
  useContext,
  createContext
} from 'react';
import { GlobalContent } from './types';

export const Context = createContext<GlobalContent>({
  setLoading: () => {},
  snackBarEvent: () => {},
  getTag: () => {},
  changeLang: () => {},
  locale: '',
  loading: false
});
export const usePageContext = () => useContext(Context);
