import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate
} from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import theme from './components/Theme';
import Book from './components/products/Products';
import BookingLayout from './components/Layouts/BookingLayout';
import DashboardLayout from './components/Layouts/DashboardLayout';
import Login from './components/dashboard/login/Login';
import Bookings from './components/dashboard/bookings/Bookings';
import Booking from './components/dashboard/booking/Booking';
import EditBooking from './components/dashboard/booking/EditBooking';
import AddBooking from './components/dashboard/booking/AddBooking';
import Products from './components/dashboard/products/Products';
import ChangeProduct from './components/dashboard/products/changeProduct/ChangeProduct';
import AddProduct from './components/dashboard/products/addProduct/AddProduct';
import Addons from './components/dashboard/addons/Addons';
import AddAddon from './components/dashboard/addons/addAddon/AddAddon';
import ChangeAddon from './components/dashboard/addons/changeAddon/ChangeAddon';
import Cart from './components/cart/Cart';
import Checkout from './components/cart/checkout/CheckoutWrapper';
import PaymentSuccess from './components/cart/checkout/PaymentSuccess';
import DefaultPage from './components/defaultPage/DefaultPage';
import { stripePublikKey, stripeConnectedAccount } from './helpers/Config';
import SmartumSuccess from './components/cart/checkout/SmartumSuccess';
import EpassiRedirect from './components/EpassiRedirect';
import EpassiSuccess from './components/cart/checkout/EpassiSuccess';

const stripePromise = loadStripe(stripePublikKey, {
  stripeAccount: stripeConnectedAccount
});

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route path='hyyre/checkout/:clientSecret' element={<Checkout />} />
          <Route element={<BookingLayout />}>
            <Route path='hyyre' element={<Book />} />
            <Route path='hyyre/cart' element={<Cart />} />
            <Route path='hyyre/epassi' element={<EpassiRedirect {...{ redirect: true }} />} />
            <Route path='hyyre/success' element={<SmartumSuccess />} />
            <Route path='hyyre/epassi/success' element={<EpassiSuccess />} />
            <Route
              path='/hyyre/paymentstatus'
              element={(
                <Elements stripe={stripePromise}>
                  <PaymentSuccess />
                </Elements>
              )}
            />
          </Route>
          <Route path='login' element={<Login />} />
          <Route element={<DashboardLayout />}>
            <Route path='dashboard' element={<Navigate to='/dashboard/bookings' />} />
            <Route path='dashboard/bookings' element={<Bookings />} />
            <Route path='dashboard/bookings/:uuid' element={<Booking />} />
            <Route path='dashboard/bookings/edit/:uuid' element={<EditBooking />} />
            <Route path='dashboard/bookings/add' element={<AddBooking />} />
            <Route path='dashboard/products' element={<Products />} />
            <Route path='dashboard/products/:uuid' element={<ChangeProduct />} />
            <Route path='dashboard/products/add' element={<AddProduct />} />
            <Route path='dashboard/addons' element={<Addons />} />
            <Route path='dashboard/addons/add' element={<AddAddon />} />
            <Route path='dashboard/addons/:uuid' element={<ChangeAddon />} />
          </Route>
          <Route path='*' element={<DefaultPage />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}
