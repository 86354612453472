import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Button,
  Grid,
  Typography
} from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { usePageContext } from '../../../Context';
import DateTimePickers from '../../products/dateTimePickers/DateTimePickers';
import styles from './EditBooking.module.css';
import { getDisabledDates, checkDateChange, saveDateChange } from '../../../services/ProductService';

type HighlightedDay = {
  date: Dayjs;
  styles: React.CSSProperties;
};

export default function EditBooking() {
  const [fromCurrentValue, setFromCurrentValue] = useState<Dayjs | null>(dayjs().add(1, 'day'));
  const [toCurrentValue, setToCurrentValue] = useState<Dayjs | null>(dayjs().add(2, 'day'));
  const [selectedHours, setSelectedHours] = useState<number | undefined>(0);
  const [highlightedDays, setHighlightedDays] = useState<HighlightedDay[]>([]);
  const [daysAmount, setDaysAmount] = useState<number>(0);
  const [montView, setMontView] = useState<Dayjs | null>(dayjs().add(1, 'day'));
  const [free, setFree] = useState<boolean>(true);
  const navigate = useNavigate();
  const { uuid } = useParams();
  const {
    getTag,
    snackBarEvent,
    setLoading
  } = usePageContext();

  const fetchHighlightedDays = async () => {
    if (montView) {
      let start = (montView.get('month') + 1).toString();
      let end = (montView.get('month') + 3).toString();
      if (start.length === 1) { start = `0${start}`; }
      if (end.length === 1) { end = `0${end}`; }
      try {
        const data: [{ date: string, color: string }] = await getDisabledDates(
          `${montView.get('year').toString()}-${start}-01,${montView.get('year')}-${end}-01`
        );
        const modifiedData: HighlightedDay[] = [];
        data.forEach((e: { date: string, color: string }) => {
          modifiedData.push({
            date: dayjs(e.date),
            styles: { backgroundColor: e.color }
          });
        });
        setHighlightedDays(modifiedData);
      } catch (error) {
        snackBarEvent('error', getTag('unabletofetchproducts'));
      }
    }
  };

  const dateChange = async () => {
    setLoading(true);
    const to = daysAmount ? dayjs(toCurrentValue).format('YYYY-MM-DD') : selectedHours;
    if (fromCurrentValue && to) {
      try {
        if (uuid) {
          const res = await checkDateChange(uuid, dayjs(fromCurrentValue).format('YYYY-MM-DD'), to.toString());
          setFree(res);
        }
      } catch (error) {
        snackBarEvent('error', getTag('somethingWentWrong'));
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchHighlightedDays();
  }, [montView, daysAmount]);

  useEffect(() => {
    dateChange();
  }, [fromCurrentValue, toCurrentValue, selectedHours]);

  useEffect(() => {
    if (daysAmount) setToCurrentValue(dayjs(fromCurrentValue).add(1, 'day'));
  }, [fromCurrentValue]);

  const save = async () => {
    setLoading(true);
    const to = daysAmount ? dayjs(toCurrentValue).format('YYYY-MM-DD') : selectedHours;
    if (fromCurrentValue && to) {
      try {
        await saveDateChange(uuid!, dayjs(fromCurrentValue).format('YYYY-MM-DD'), to.toString());
        navigate(`/dashboard/bookings/${uuid}`);
      } catch (error) {
        snackBarEvent('error', getTag('somethingWentWrong'));
      }
    }
    setLoading(false);
  };

  return (
    <Grid className={styles.gridWrapper} container spacing={0}>
      <Grid item xs={12}>
        <DateTimePickers
          fromCurrentValue={fromCurrentValue}
          setFromCurrentValue={setFromCurrentValue}
          toCurrentValue={toCurrentValue}
          setToCurrentValue={setToCurrentValue}
          selectedHours={selectedHours}
          setSelectedHours={setSelectedHours}
          highlightedDays={highlightedDays}
          daysAmount={daysAmount}
          setDaysAmount={setDaysAmount}
          setMontView={setMontView}
        />
      </Grid>
      <Grid className={styles.textDiv} item xs={12}>
        {free ? (
          <Typography variant='h6' className={styles.freeText} sx={{ color: '#bad85f' }}>{getTag('timeAvailable')}</Typography>
        ) : (
          <Typography variant='h6' className={styles.freeText} sx={{ color: '#ff0000' }}>{getTag('timeNotAvailable')}</Typography>
        )}
      </Grid>
      <Grid className={styles.buttonDiv} item xs={12}>
        <Button className={styles.backButton} onClick={() => navigate(`/dashboard/bookings/${uuid}`)}>
          {getTag('back')}
        </Button>
        <Button
          sx={!free ? { backgroundColor: 'lightgrey' } : { backgroundColor: '#bad85f' }}
          disabled={!free}
          className={styles.saveButton}
          onClick={save}
        >
          {getTag('save')}
        </Button>
      </Grid>
    </Grid>
  );
}
