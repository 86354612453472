import React, { useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Card,
  TextField,
  Button
} from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import styles from './Login.module.css';
import { usePageContext } from '../../../Context';
import LangugageMenu from '../../navbar/LanguageMenu';
import { logIn, logOut } from '../../../services/AuthService';

export default function Login() {
  const { setLoading, snackBarEvent, getTag } = usePageContext();
  const email = useRef<HTMLInputElement>();
  const password = useRef<HTMLInputElement>();
  const navigate = useNavigate();

  const navbarFontSize = 14;
  const navbarFontWeight = 700;

  useEffect(() => {
    document.title = 'Login';
    logOut();
  }, []);

  const signin = async () => {
    setLoading(true);
    if (email.current?.value && password.current?.value) {
      try {
        await logIn(email.current.value, password.current.value);
        navigate('/dashboard/bookings');
      } catch (err: any) {
        if (err.response.status === 401) {
          snackBarEvent('error', getTag('wrongUsernameOrPassword'));
        } else {
          snackBarEvent('error', getTag('somethingWentWrong'));
        }
      }
    } else {
      snackBarEvent('error', getTag('wrongUsernameOrPassword'));
    }
    setLoading(false);
  };

  return (
    <div className={styles.wrapper}>
      <Card className={styles.loginBox}>
        <PersonIcon sx={{ fontSize: 100 }} />
        <TextField sx={{ width: '100%', marginBottom: '4%' }} variant='filled' label='Email' inputRef={email} />
        <TextField sx={{ width: '100%', marginBottom: '4%' }} variant='filled' type='password' label={getTag('password')} inputRef={password} />
        <Button className={styles.loginButton} sx={{ marginBottom: '2%', backgroundColor: '#202124' }} variant='contained' onClick={signin}>
          {getTag('signin')}
        </Button>
        <LangugageMenu color='#000' navbarFontSize={navbarFontSize} navbarFontWeight={navbarFontWeight} />
      </Card>
    </div>
  );
}
