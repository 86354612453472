import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Grid,
  Typography,
  Button,
  TextField,
  FormControlLabel,
  Checkbox
} from '@mui/material';
import { usePageContext } from '../../../../Context';
import styles from './ChangeAddon.module.css';
import {
  changeAddon,
  changeAddonPicture,
  uploadAddonPicture,
  getAddon,
  deleteAddon
} from '../../../../services/AddonService';

export default function AddAddons() {
  const { uuid } = useParams();
  const { getTag, setLoading, snackBarEvent } = usePageContext();
  const [nameSv, setNameSv] = useState('');
  const [nameFi, setNameFi] = useState('');
  const [price, setPrice] = useState(0);
  const [quantity, setQuantity] = useState(0);
  const [currImg, setCurrImg] = useState('');
  const [id, setId] = useState(0);
  const [img, setImg] = useState();
  const navigate = useNavigate();
  const [keepTrack, setKeepTrack] = useState<boolean>(false);

  const fetchData = async () => {
    setLoading(true);
    try {
      const data = await getAddon(uuid!);
      setId(data.id);
      setNameSv(data.nameSv);
      setNameFi(data.nameFi);
      setPrice(data.price);
      setQuantity(data.quantity);
      setCurrImg(data.addonPicture);
      setKeepTrack(data.keepTrack);
    } catch (error) {
      snackBarEvent('error', getTag('unabletofetchproducts'));
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const formValidation: { [key: string]: boolean } = {
    nameSv: nameSv.length > 0,
    nameFi: nameFi.length > 0,
    price: (price ?? 0) >= 0,
    quantity: (quantity ?? 0) >= 0
  };

  const formValid = () => Object.keys(formValidation).every((e) => formValidation[e]);

  const saveData = async () => {
    setLoading(true);
    const data = {
      nameSv,
      nameFi,
      price,
      quantity,
      keepTrack
    };
    try {
      await changeAddon(uuid!, data);
      if (img) {
        const image = new FormData();
        image.append(
          'image',
          img,
          id.toString()
        );
        if (currImg) {
          await changeAddonPicture(image);
        } else {
          await uploadAddonPicture(image);
        }
        fetchData();
        snackBarEvent('success', getTag('editedAddon'));
      } else {
        snackBarEvent('success', getTag('editedAddon'));
      }
    } catch (error) {
      snackBarEvent('error', getTag('somethingWentWrong'));
    }
    setLoading(false);
  };

  const del = async () => {
    if (uuid) {
      try {
        await deleteAddon(uuid);
        navigate('/dashboard/addons');
      } catch (error) {
        snackBarEvent('error', getTag('somethingWentWrong'));
      }
    }
  };

  const handleKeepTrack = (e: React.ChangeEvent<HTMLInputElement>) => {
    setKeepTrack(e.target.checked);
  };

  return (
    <Grid className={styles.productCont} container spacing={0}>
      <Grid className={styles.gridTop} xs={12} lg={12}>
        <Typography sx={{ fontWeight: 'bold' }} variant='h6'>{getTag('editAddon')}</Typography>
        <Button className={styles.deletButton} onClick={() => del()}>
          {getTag('delete')}
        </Button>
      </Grid>
      <Grid className={styles.gridItem} xs={6}>
        <TextField
          required
          sx={{ width: '99%' }}
          error={!formValidation.nameSv}
          variant='filled'
          size='small'
          value={nameSv}
          label={`${getTag('name')} - Sv`}
          onChange={(e) => setNameSv(e.target.value)}
        />
      </Grid>
      <Grid className={styles.gridItem} xs={6}>
        <TextField
          required
          sx={{ width: '99%' }}
          error={!formValidation.nameFi}
          variant='filled'
          size='small'
          value={nameFi}
          label={`${getTag('name')} - Fi`}
          onChange={(e) => setNameFi(e.target.value)}
        />
      </Grid>
      <Grid className={styles.gridItem} xs={6} lg={6}>
        <TextField
          required
          sx={{ width: '99%' }}
          error={!formValidation.price}
          variant='filled'
          size='small'
          value={price}
          type='number'
          label={getTag('price')}
          onChange={(e) => setPrice(parseFloat(e.target.value))}
        />
      </Grid>
      <Grid className={styles.gridItem} xs={6} lg={6}>
        <TextField
          required
          sx={{ width: '99%' }}
          error={!formValidation.quantity}
          variant='filled'
          size='small'
          value={quantity}
          type='number'
          label={getTag('quantity')}
          onChange={(e) => setQuantity(parseInt(e.target.value))}
        />
      </Grid>
      <Grid className={styles.gridItem} xs={12}>
        <div className={styles.keepTrackDiv}>
          <FormControlLabel control={<Checkbox checked={keepTrack} onChange={handleKeepTrack} />} label={getTag('keepTrack')} />
        </div>
      </Grid>
      <Grid className={styles.gridItem} xs={12}>
        <div className={styles.leftPictureDiv}>
          <Typography sx={{ fontWeight: 'bold' }}>{getTag('productPicture')}</Typography>
          <img style={{ width: '50%' }} src={`data:image/png;base64,${currImg}`} alt='product' />
        </div>
        <div className={styles.rightPictureDiv}>
          <input
            style={{ float: 'right', padding: '0.4vw' }}
            type='file'
            name='productImage'
            onChange={(event: any) => setImg(event.target.files[0])}
          />
        </div>
      </Grid>
      <Grid className={styles.gridSave} xs={12} lg={12}>
        <Button type='submit' disabled={!formValid()} className={styles.saveButton} onClick={saveData}>
          {getTag('save')}
        </Button>
      </Grid>
    </Grid>
  );
}
