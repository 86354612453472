import React, { Dispatch } from 'react';
import {
  Grid,
  Select,
  MenuItem,
  Typography
} from '@mui/material';
import { usePageContext } from '../../../Context';
import styles from './AddonView.module.css';
import { AddonsWithPicture } from '../../../types';

type propsInterface = {
  setAddonsAmount: Dispatch<{ [key: number]: number } | undefined>;
  addons: AddonsWithPicture[];
  addonsAmount: { [key: number]: number } | undefined;
  setRelatedAddonsAmount: Dispatch<{ [key: number]: number } | undefined>;
  relatedAddons: AddonsWithPicture[];
  relatedAddonsAmount: { [key: number]: number } | undefined;
}

export default function AddonView(props: propsInterface) {
  const {
    addons,
    addonsAmount,
    setAddonsAmount,
    relatedAddons,
    relatedAddonsAmount,
    setRelatedAddonsAmount
  } = props;
  const {
    getTag,
    locale
  } = usePageContext();

  const amount = (e: number) => {
    const rows = [];
    for (let i = 0; i <= e; i++) {
      rows.push(<MenuItem value={i}>{i}</MenuItem>);
    }
    return rows;
  };

  const changeAddonAmount = (id: number, event: number) => {
    if (!addonsAmount) return;
    const newJson: { [key: number]: number } | undefined = { ...addonsAmount };
    newJson[id] = event;
    setAddonsAmount(newJson);
  };

  const changeRelatedAddonAmount = (id: number, event: number) => {
    if (!relatedAddonsAmount) return;
    const newJson: { [key: number]: number } | undefined = { ...relatedAddonsAmount };
    newJson[id] = event;
    setRelatedAddonsAmount(newJson);
  };

  return (
    <Grid className={styles.gridWrapper} container spacing={0}>
      <Grid xs={12}>
        <Typography variant='h6' sx={{ fontWeight: 'bold' }}>{getTag('addons')}</Typography>
      </Grid>
      {relatedAddonsAmount && relatedAddons?.map((p) => (
        <Grid className={styles.productCont} container>
          <Grid className={styles.pictureBox} xs={3}>
            <img className={styles.featuredPicture} src={`data:image/png;base64,${p.addonPicture}`} alt='product' />
          </Grid>
          <Grid className={styles.itemBox} xs={6}>
            <div>
              <Typography className={styles.itemText}>
                {locale === 'sv' ? p.nameSv : p.nameFi}
              </Typography>
              <Typography sx={{ textAlign: 'center' }}>
                {`${p.price}€/${getTag('pcs')}`}
              </Typography>
            </div>
          </Grid>
          <Grid className={styles.itemBox} xs={3}>
            {p.quantity === 0 ? (
              <Typography className={styles.itemText} sx={{ color: 'red' }}>
                {getTag('booked')}
              </Typography>
            ) : (
              <Select
                id={p.id.toString()}
                className={styles.calendarsPadding}
                value={relatedAddonsAmount[p.id]}
                label={getTag('Tid')}
                onChange={(e) => changeRelatedAddonAmount(p.id, Number(e.target.value))}
              >
                {amount(p.quantity)}
              </Select>
            )}
          </Grid>
        </Grid>
      ))}
      {addonsAmount && addons?.map((p) => (
        <Grid className={styles.productCont} container>
          <Grid className={styles.pictureBox} xs={3}>
            <img className={styles.featuredPicture} src={`data:image/png;base64,${p.addonPicture}`} alt='product' />
          </Grid>
          <Grid className={styles.itemBox} xs={6}>
            <div>
              <Typography className={styles.itemText}>
                {locale === 'sv' ? p.nameSv : p.nameFi}
              </Typography>
              <Typography sx={{ textAlign: 'center' }}>
                {`${p.price}€/${getTag('pcs')}`}
              </Typography>
            </div>
          </Grid>
          <Grid className={styles.itemBox} xs={3}>
            {p.quantity === 0 ? (
              <Typography className={styles.itemText} sx={{ color: 'red' }}>
                {getTag('booked')}
              </Typography>
            ) : (
              <Select
                id={p.id.toString()}
                className={styles.calendarsPadding}
                value={addonsAmount[p.id]}
                label={getTag('Tid')}
                onChange={(e) => changeAddonAmount(p.id, Number(e.target.value))}
              >
                {amount(p.quantity)}
              </Select>
            )}
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
}
