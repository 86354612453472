import dev from './config.development.json';
import prod from './config.production.json';
import env from './env.json';

const config = env.env === 'development' ? dev : prod;

export const baseURL = config.BACKEND_BASE;
export const frontendURL = config.FRONTEND_BASE;
export const defaultLang = config.DEFAULT_LANG;
export const stripePublikKey = config.STRIPE_PUBLIC_KEY;
export const stripeConnectedAccount = config.STRIPE_CONNECTED_ACCOUNT_ID;
