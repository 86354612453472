import { get, post, put } from '../helpers/BackendService';
import { NavbarPictures, FooterPictures, cartPictures } from '../types';

export function getNavbarPictures(): Promise<NavbarPictures> {
  return get('pictures/navbar');
}

export function getFooterPictures(): Promise<FooterPictures> {
  return get('pictures/footer');
}

export function getCartPictures(): Promise<cartPictures> {
  return get('pictures/cart');
}

export function uploadProductPicture(data: object) {
  return post('productPictures/featured', data);
}

export function uploadProductBackground(data: object) {
  return post('productPictures/background', data);
}

export function changeProductPicture(data: object) {
  return put('productPictures/featured', data);
}

export function changeProductBackground(data: object) {
  return put('productPictures/background', data);
}
