import React from 'react';
import styles from './Checkout.module.css';
import { usePageContext } from '../../../Context';

export default function EpassiSuccess() {
  const { getTag } = usePageContext();
  return (
    <div className={styles.statusWrapper}>
      <div className={styles.cardBox}>
        <h2>{getTag('paymentSucceded')}</h2>
        <h2>{getTag('emailText')}</h2>
      </div>
    </div>
  );
}
