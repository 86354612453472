import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Grid,
  Typography
} from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import styles from './Products.module.css';
import { usePageContext } from '../../../Context';
import { getProducts } from '../../../services/ProductService';
import { AllProducts } from '../../../types';

export default function Products() {
  const { setLoading, snackBarEvent, getTag } = usePageContext();
  const [items, setItems] = useState<AllProducts[]>();
  const navigate = useNavigate();

  const fetchData = async () => {
    setLoading(true);
    try {
      const data = await getProducts();
      setItems(data);
    } catch (error) {
      snackBarEvent('error', getTag('unabletofetchproducts'));
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Grid className={styles.gridWrapper} container spacing={0}>
      <Grid className={styles.addButton} item xs={12}>
        <AddCircleIcon className={styles.addIcon} onClick={() => navigate('/dashboard/products/add')} />
      </Grid>
      {items?.map((p) => (
        <Grid className={styles.productCont} container onClick={() => navigate(`/dashboard/products/${p.uuid}`)}>
          <Grid className={styles.pictureBox} xs={3}>
            <img className={styles.featuredPicture} src={`data:image/png;base64,${p.featuredPicture}`} alt='product' />
          </Grid>
          <Grid className={styles.itemBox} xs={9}>
            <div>
              <Typography className={styles.itemText}>
                {p.manufacturer}
              </Typography>
              <Typography className={styles.itemText}>
                {p.model}
              </Typography>
            </div>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
}
