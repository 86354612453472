import React, { useEffect, useRef, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Grid,
  Typography,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  Select,
  MenuItem,
  InputLabel,
  FormControl
} from '@mui/material';
import styles from './ChangeProduct.module.css';
import { usePageContext } from '../../../../Context';
import {
  getProduct,
  changeProduct,
  deleteProduct,
  getProductTypes,
  getTimeFrames
} from '../../../../services/ProductService';
import { changeProductPicture, changeProductBackground } from '../../../../services/PictureService';
import { getDashboardRelations } from '../../../../services/AddonService';
import {
  Product,
  DashboardRelations,
  timeFrame,
  ProductTypes
} from '../../../../types';

export default function ChangeProduct() {
  const { uuid } = useParams();
  const {
    setLoading,
    snackBarEvent,
    getTag,
    locale
  } = usePageContext();
  const [item, setItem] = useState<Product>();
  const [productType, setProductType] = useState<number>();
  const [productTypes, setProductTypes] = useState<ProductTypes[]>([]);
  const [timeFrames, setTimeFrames] = useState<timeFrame[]>([]);
  const [relations, setRelations] = useState<DashboardRelations[]>([]);
  const [img, setImg] = useState();
  const [background, setBackground] = useState();
  const [featured, setFeatured] = useState<boolean>(false);
  const navigate = useNavigate();

  const manufacturer = useRef<HTMLInputElement>();
  const model = useRef<HTMLInputElement>();
  const quantity = useRef<HTMLInputElement>();
  const infoSv = useRef<HTMLInputElement>();
  const infoFi = useRef<HTMLInputElement>();
  const technicalSv = useRef<HTMLInputElement>();
  const technicalFi = useRef<HTMLInputElement>();
  const videoLink = useRef<HTMLInputElement>();

  const fetchData = async () => {
    setLoading(true);
    try {
      const data = await getProduct(uuid!);
      setFeatured(data.featured);
      setItem(data);
      setProductType(data.productType);
    } catch (err) {
      snackBarEvent('error', getTag('unabletofetchproducts'));
    }
    try {
      const types = await getProductTypes();
      setProductTypes(types);
    } catch (err) {
      snackBarEvent('error', getTag('unabletofetchproducts'));
    }
    try {
      const rel = await getDashboardRelations(uuid!);
      setRelations(rel);
    } catch (err) {
      snackBarEvent('error', getTag('unabletofetchproducts'));
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchTimeFrames = async () => {
    setLoading(true);
    let frames = await getTimeFrames(productType);
    if (productType === item?.productType) {
      frames = frames.map((f: any) => ({ ...f, ...item?.prices.find((p) => p.timeFrame === f.id) }));
    }
    setTimeFrames(frames);
    setLoading(false);
  };

  useEffect(() => {
    if (productType) fetchTimeFrames();
  }, [productType]);

  const saveData = async () => {
    setLoading(true);
    if (
      manufacturer.current?.value
      && model.current?.value
      && quantity.current?.value
      && infoSv.current?.value
      && infoFi.current?.value
      && technicalSv.current?.value
      && technicalFi.current?.value
    ) {
      if (timeFrames.some((e) => (!e.price || !e.id))) {
        snackBarEvent('error', getTag('setAllFields'));
        setLoading(false);
        return;
      }
      const data = {
        manufacturer: manufacturer.current?.value,
        model: model.current?.value,
        quantity: quantity.current?.value,
        featured,
        timeFrames,
        infoSv: infoSv.current?.value,
        infoFi: infoFi.current?.value,
        technicalSv: technicalSv.current?.value,
        technicalFi: technicalFi.current?.value,
        link: videoLink.current?.value,
        relations
      };
      await changeProduct(uuid, data).then(
        async () => {
          if (img) {
            const image = new FormData();
            image.append(
              'image',
              img,
              uuid
            );
            await changeProductPicture(image).then(
              () => {
                snackBarEvent('success', getTag('changedProduct'));
              },
              () => {
                snackBarEvent('error', getTag('somethingWentWrong'));
              }
            );
          }
          if (background) {
            const image = new FormData();
            image.append(
              'image',
              background,
              uuid
            );
            await changeProductBackground(image).then(
              () => {
                snackBarEvent('success', getTag('changedProduct'));
              },
              () => {
                snackBarEvent('error', getTag('somethingWentWrong'));
              }
            );
          }
          if (!img && !background) {
            snackBarEvent('success', getTag('changedProduct'));
          }
          fetchData();
        },
        () => {
          snackBarEvent('error', getTag('somethingWentWrong'));
        }
      );
    } else {
      snackBarEvent('error', getTag('setAllFields'));
    }
    setLoading(false);
  };

  const del = () => {
    if (uuid) {
      deleteProduct(uuid).then(
        () => navigate('/dashboard/products'),
        () => snackBarEvent('error', getTag('somethingWentWrong'))
      );
    }
  };

  const handleFeatured = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFeatured(e.target.checked);
  };

  function changePrice(index: number, event: any) {
    const newArr = [...timeFrames];
    newArr[index].price = Number(event.target.value);
    setTimeFrames(newArr);
  }

  function handleRelations(index: number, event: any) {
    if (!relations) return;
    const newArr = [...relations];
    newArr[index].linked = event.target.checked;
    setRelations(newArr);
  }

  return (
    item ? (
      <Grid className={styles.productCont} container spacing={0}>
        <Grid className={styles.gridTop} xs={12} lg={12}>
          <Typography sx={{ fontWeight: 'bold' }} variant='h6'>{getTag('editProduct')}</Typography>
          <Button className={styles.deletButton} onClick={() => del()}>
            {getTag('delete')}
          </Button>
        </Grid>
        <Grid className={styles.gridItem} xs={6} lg={6}>
          <TextField
            sx={{ width: '99%' }}
            defaultValue={item.manufacturer}
            variant='filled'
            size='small'
            label={getTag('manufacturer')}
            inputRef={manufacturer}
          />
        </Grid>
        <Grid className={styles.gridItem} xs={6} lg={6}>
          <TextField
            sx={{ width: '99%' }}
            defaultValue={item.model}
            variant='filled'
            size='small'
            label={getTag('model')}
            inputRef={model}
          />
        </Grid>
        <Grid className={styles.gridItem} xs={6} lg={6}>
          <TextField
            required
            sx={{ width: '99%' }}
            defaultValue={item.quantity}
            variant='filled'
            size='small'
            type='number'
            label={getTag('quantity')}
            inputRef={quantity}
          />
        </Grid>
        <Grid className={styles.gridItem} xs={6} lg={6}>
          <FormControlLabel control={<Checkbox checked={featured} onChange={handleFeatured} />} label={getTag('featured')} />
        </Grid>
        <Grid className={styles.gridPrice} xs={12} lg={12}>
          <Typography sx={{ fontWeight: 'bold', margin: '1vh 0' }}>{getTag('price')}</Typography>
          <FormControl sx={{ width: '48.5%', margin: '0 51.5% 1vh 0' }}>
            <InputLabel>produkt typ</InputLabel>
            <Select
              value={productType}
              variant='filled'
              size='small'
              onChange={(e) => setProductType(Number(e.target.value))}
            >
              {productTypes && productTypes.map(
                (e: any) => (
                  <MenuItem value={e.id}>{e.name}</MenuItem>
                )
              )}
            </Select>
          </FormControl>
          {timeFrames && timeFrames.map(
            (e: any, i: number) => (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography sx={{ width: '49.5%', marginLeft: '0.75%' }}>
                  {`${e.fromTime} - ${e.toTime} ${e.fullDay ? ` - ${getTag('fullDay')}` : ''}`}
                </Typography>
                <TextField
                  required
                  sx={{ width: '49.5%' }}
                  defaultValue={e.price}
                  variant='filled'
                  size='small'
                  type='number'
                  label={getTag('price')}
                  onChange={(event) => changePrice(i, event)}
                />
              </div>
            )
          )}
        </Grid>
        <Grid className={styles.gridItem} xs={12} lg={6}>
          <TextField
            sx={{ width: '99%' }}
            multiline
            defaultValue={item.info.sv}
            variant='filled'
            size='small'
            label={`${getTag('info')} Sv`}
            inputRef={infoSv}
          />
        </Grid>
        <Grid className={styles.gridItem} xs={12} lg={6}>
          <TextField
            sx={{ width: '99%' }}
            multiline
            defaultValue={item.info.fi}
            variant='filled'
            size='small'
            label={`${getTag('info')} Fi`}
            inputRef={infoFi}
          />
        </Grid>
        <Grid className={styles.gridItem} xs={12} lg={6}>
          <TextField
            sx={{ width: '99%' }}
            multiline
            defaultValue={item.technical.sv}
            variant='filled'
            size='small'
            label={`${getTag('technicalDetails')} Sv`}
            inputRef={technicalSv}
          />
        </Grid>
        <Grid className={styles.gridItem} xs={12} lg={6}>
          <TextField
            sx={{ width: '99%' }}
            multiline
            defaultValue={item.technical.fi}
            variant='filled'
            size='small'
            label={`${getTag('technicalDetails')} Fi`}
            inputRef={technicalFi}
          />
        </Grid>
        <Grid className={styles.gridItem} xs={12} lg={12}>
          <TextField
            sx={{ width: '100%' }}
            defaultValue={item.video}
            variant='filled'
            size='small'
            label={getTag('videoLink')}
            inputRef={videoLink}
          />
        </Grid>
        <Grid className={styles.gridItem} xs={12} lg={12}>
          <div className={styles.leftPictureDiv}>
            <Typography sx={{ fontWeight: 'bold' }}>{getTag('productPicture')}</Typography>
            <img style={{ width: '100%' }} src={`data:image/png;base64,${item.pictures.featuredPicture.picture}`} alt='product' />
          </div>
          <div className={styles.rightPictureDiv}>
            <input
              style={{ float: 'right', padding: '0.4vw' }}
              type='file'
              name='productImage'
              onChange={(event: any) => setImg(event.target.files[0])}
            />
          </div>
        </Grid>
        <Grid className={styles.gridItem} xs={12} lg={12}>
          <div className={styles.leftPictureDiv}>
            <Typography sx={{ fontWeight: 'bold' }}>{getTag('productBackground')}</Typography>
            <img style={{ width: '100%' }} src={`data:image/png;base64,${item.pictures.background.picture}`} alt='product' />
          </div>
          <div className={styles.rightPictureDiv}>
            <input
              style={{ float: 'right', padding: '0.4vw' }}
              type='file'
              name='productBackground'
              onChange={(event: any) => setBackground(event.target.files[0])}
            />
          </div>
        </Grid>
        <Grid className={styles.gridItem} xs={12} lg={12}>
          <Typography sx={{ fontWeight: 'bold' }}>{getTag('addonsOnlyBookedWithProduct')}</Typography>
        </Grid>
        {relations && relations.map(
          (e: any, i: number) => (
            <Grid className={styles.gridItem} xs={6} lg={6}>
              <FormControlLabel
                control={
                  <Checkbox checked={e.linked} onChange={(event) => handleRelations(i, event)} />
                }
                label={locale === 'sv' ? e.nameSv : e.nameFi}
              />
            </Grid>
          )
        )}
        <Grid className={styles.gridSave} xs={12} lg={12}>
          <Button className={styles.saveButton} onClick={saveData}>
            {getTag('save')}
          </Button>
        </Grid>
      </Grid>
    ) : (null)
  );
}
