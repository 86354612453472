import {
  get,
  post,
  del
} from '../helpers/BackendService';
import { BookingListItem, BookingType } from '../types';

export function getBookings(): Promise<BookingListItem[]> {
  return get('bookings');
}

export function getBooking(uuid: string): Promise<BookingType> {
  return get(`bookings/${uuid}`);
}

export function addBooking(data: object) {
  return post('bookings/', data);
}

export function addAdminBooking(data: object) {
  return post('bookings/adminBooking', data);
}

export function deleteBooking(uuid: string) {
  return del('bookings/', uuid);
}
