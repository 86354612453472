import { H } from 'highlight.run';
import env from '../helpers/env.json';

export function identify(email: string) {
  if (env.env === 'production') {
    H.identify(email);
  }
}

export function init() {
  if (env.env === 'production') {
    H.init('jd4m89d5', {
      tracingOrigins: true,
      networkRecording: {
        enabled: true,
        recordHeadersAndBody: true,
        urlBlocklist: []
      }
    });
  }
}
