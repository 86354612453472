import { Link, useLocation } from 'react-router-dom';
import React, { useState, MouseEvent } from 'react';
import {
  Menu,
  MenuItem,
  IconButton
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Pages } from '../../types';
import { usePageContext } from '../../Context';

interface PropsInterface {
  pages?: Pages[];
  navbarFontWeight: number;
  navbarFontSize: number;
}

export default function DrawerComponent(props: PropsInterface) {
  const location = useLocation();
  const { pages, navbarFontWeight, navbarFontSize } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { getTag } = usePageContext();
  const pathName = location.pathname.split('/')[1];

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        {pathName === 'dashboard' ? (
          pages?.map((page) => (
            <MenuItem
              sx={{
                fontWeight: navbarFontWeight,
                fontSize: navbarFontSize
              }}
              onClick={handleClose}
              component={Link}
              to={page.path}
            >
              {getTag(page.name)}
            </MenuItem>
          ))
        ) : (
          pages?.map((page) => (
            <MenuItem
              sx={{
                fontWeight: navbarFontWeight,
                fontSize: navbarFontSize
              }}
              onClick={() => window.location.assign(page.path)}
            >
              {getTag(page.name)}
            </MenuItem>
          ))
        )}
      </Menu>
      <IconButton
        style={{ color: '#fff' }}
        onClick={handleClick}
      >
        <MenuIcon sx={{ fontWeight: navbarFontWeight, fontSize: 40 }} />
      </IconButton>
    </>
  );
}
