import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Grid,
  Typography,
  Button,
  TextField,
  FormControlLabel,
  Checkbox
} from '@mui/material';
import { usePageContext } from '../../../../Context';
import styles from './AddAddon.module.css';
import { addAddon, uploadAddonPicture } from '../../../../services/AddonService';

export default function AddAddon() {
  const { getTag, setLoading, snackBarEvent } = usePageContext();
  const [nameSv, setNameSv] = useState('');
  const [nameFi, setNameFi] = useState('');
  const [price, setPrice] = useState(0);
  const [quantity, setQuantity] = useState(0);
  const [img, setImg] = useState();
  const navigate = useNavigate();
  const [keepTrack, setKeepTrack] = useState<boolean>(false);

  const formValidation: { [key: string]: boolean } = {
    nameSv: nameSv.length > 0,
    nameFi: nameFi.length > 0,
    price: (price ?? 0) >= 0,
    quantity: (quantity ?? 0) > 0
  };

  const formValid = () => Object.keys(formValidation).every((e) => formValidation[e]);

  const saveData = async () => {
    setLoading(true);
    const d = {
      nameSv,
      nameFi,
      price,
      quantity,
      keepTrack
    };
    try {
      const res = await addAddon(d);
      if (img) {
        const image = new FormData();
        const id = res.data.toString();
        image.append(
          'image',
          img,
          id
        );
        await uploadAddonPicture(image);
      }
      navigate('/dashboard/addons');
    } catch (error) {
      snackBarEvent('error', getTag('somethingWentWrong'));
    }

    setLoading(false);
  };

  const handleKeepTrack = (e: React.ChangeEvent<HTMLInputElement>) => {
    setKeepTrack(e.target.checked);
  };

  return (
    <Grid className={styles.productCont} container spacing={0}>
      <Grid className={styles.gridTop} xs={12} lg={12}>
        <Typography sx={{ fontWeight: 'bold' }} variant='h6'>{getTag('add')}</Typography>
      </Grid>
      <Grid className={styles.gridItem} xs={6}>
        <TextField
          required
          sx={{ width: '99%' }}
          error={!formValidation.nameSv}
          variant='filled'
          size='small'
          label={`${getTag('name')} - Sv`}
          onChange={(e) => setNameSv(e.target.value)}
        />
      </Grid>
      <Grid className={styles.gridItem} xs={6}>
        <TextField
          required
          sx={{ width: '99%' }}
          error={!formValidation.nameSv}
          variant='filled'
          size='small'
          label={`${getTag('name')} - Fi`}
          onChange={(e) => setNameFi(e.target.value)}
        />
      </Grid>
      <Grid className={styles.gridItem} xs={6} lg={6}>
        <TextField
          required
          sx={{ width: '99%' }}
          error={!formValidation.price}
          variant='filled'
          size='small'
          type='number'
          label={getTag('price')}
          onChange={(e) => setPrice(parseFloat(e.target.value))}
        />
      </Grid>
      <Grid className={styles.gridItem} xs={6} lg={6}>
        <TextField
          required
          sx={{ width: '99%' }}
          error={!formValidation.quantity}
          variant='filled'
          size='small'
          type='number'
          label={getTag('quantity')}
          onChange={(e) => setQuantity(parseInt(e.target.value))}
        />
      </Grid>
      <Grid className={styles.gridItem} xs={12}>
        <div className={styles.keepTrackDiv}>
          <FormControlLabel control={<Checkbox checked={keepTrack} onChange={handleKeepTrack} />} label={getTag('keepTrack')} />
        </div>
      </Grid>
      <Grid className={styles.gridItem} xs={12} lg={12}>
        <div className={styles.leftPictureDiv}>
          <Typography sx={{ fontWeight: 'bold' }}>{`${getTag('add')} ${getTag('image')}`}</Typography>
        </div>
        <div className={styles.rightPictureDiv}>
          <input
            style={{ float: 'right', padding: '0.4vw' }}
            type='file'
            name='addonPicture'
            onChange={(event: any) => { setImg(event.target.files[0]); }}
          />
        </div>
      </Grid>
      <Grid className={styles.gridSave} xs={12} lg={12}>
        <Button type='submit' disabled={!formValid()} className={styles.saveButton} onClick={saveData}>
          {getTag('save')}
        </Button>
      </Grid>
    </Grid>
  );
}
