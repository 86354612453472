import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Grid,
  Typography,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  Select,
  MenuItem,
  InputLabel,
  FormControl
} from '@mui/material';
import styles from './AddProduct.module.css';
import { usePageContext } from '../../../../Context';
import { addProduct, getProductTypes, getTimeFrames } from '../../../../services/ProductService';
import { getAddons } from '../../../../services/AddonService';
import { uploadProductPicture, uploadProductBackground } from '../../../../services/PictureService';
import { AddonsList, timeFrame, ProductTypes } from '../../../../types';

export default function AddProduct() {
  const {
    getTag,
    setLoading,
    snackBarEvent,
    locale
  } = usePageContext();
  const [relations, setRelations] = useState<AddonsList[]>([]);
  const [productTypes, setProductTypes] = useState<ProductTypes[]>([]);
  const [productType, setProductType] = useState<number>();
  const [timeFrames, setTimeFrames] = useState<timeFrame[]>([]);
  const [img, setImg] = useState();
  const [imgName, setImgName] = useState();
  const [background, setBackground] = useState();
  const [backgroundName, setBackgroundName] = useState();
  const [featured, setFeatured] = useState<boolean>(false);
  const navigate = useNavigate();

  const manufacturer = useRef<HTMLInputElement>();
  const model = useRef<HTMLInputElement>();
  const quantity = useRef<HTMLInputElement>();
  const infoSv = useRef<HTMLInputElement>();
  const infoFi = useRef<HTMLInputElement>();
  const technicalSv = useRef<HTMLInputElement>();
  const technicalFi = useRef<HTMLInputElement>();
  const videoLink = useRef<HTMLInputElement>();

  const fetchData = async () => {
    setLoading(true);
    try {
      const types = await getProductTypes();
      setProductTypes(types);
    } catch (error) {
      snackBarEvent('error', getTag('unabletofetchproducts'));
    }
    try {
      const rel = await getAddons();
      setRelations(rel);
    } catch (error) {
      snackBarEvent('error', getTag('unabletofetchproducts'));
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchTimeFrames = async () => {
    setLoading(true);
    try {
      const Frames = await getTimeFrames(productType);
      setTimeFrames(Frames);
    } catch (error) {
      snackBarEvent('error', getTag('somethingWentWrong'));
    }
    setLoading(false);
  };

  useEffect(() => {
    if (productType) fetchTimeFrames();
  }, [productType]);

  const saveData = async () => {
    setLoading(true);
    if (
      manufacturer.current?.value
      && model.current?.value
      && quantity.current?.value
      && infoSv.current?.value
      && infoFi.current?.value
      && technicalSv.current?.value
      && technicalFi.current?.value
      && productType
      && img
      && background
      && imgName
      && backgroundName
    ) {
      if (timeFrames.some((e) => (!e.price || !e.id))) {
        snackBarEvent('error', getTag('setAllFields'));
        setLoading(false);
        return;
      }
      const data = {
        manufacturer: manufacturer.current?.value,
        model: model.current?.value,
        quantity: quantity.current?.value,
        featured,
        productType,
        timeFrames,
        infoSv: infoSv.current?.value,
        infoFi: infoFi.current?.value,
        technicalSv: technicalSv.current?.value,
        technicalFi: technicalFi.current?.value,
        link: videoLink.current?.value,
        relations
      };
      try {
        const res = await addProduct(data);
        let image = new FormData();
        const uuid = res.data.toString();
        image.append(
          'image',
          img,
          uuid
        );
        await uploadProductPicture(image);
        image = new FormData();
        image.append(
          'image',
          background,
          uuid
        );
        await uploadProductBackground(image);
        navigate('/dashboard/products');
      } catch (error) {
        snackBarEvent('error', getTag('somethingWentWrong'));
      }
    }
    setLoading(false);
  };

  const handleFeatured = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFeatured(e.target.checked);
  };

  function changePrice(index: number, event: any) {
    const newArr = [...timeFrames];
    newArr[index].price = Number(event.target.value);
    setTimeFrames(newArr);
  }

  function handleRelations(index: number, event: any) {
    if (!relations) return;
    const newArr = [...relations];
    newArr[index].linked = event.target.checked;
    setRelations(newArr);
  }

  return (
    <Grid className={styles.productCont} container spacing={0}>
      <Grid className={styles.gridTop} xs={12} lg={12}>
        <Typography sx={{ fontWeight: 'bold' }} variant='h6'>{getTag('add')}</Typography>
      </Grid>
      <Grid className={styles.gridItem} xs={6} lg={6}>
        <TextField
          required
          sx={{ width: '99%' }}
          variant='filled'
          size='small'
          label={getTag('manufacturer')}
          inputRef={manufacturer}
        />
      </Grid>
      <Grid className={styles.gridItem} xs={6} lg={6}>
        <TextField
          required
          sx={{ width: '99%' }}
          variant='filled'
          size='small'
          label={getTag('model')}
          inputRef={model}
        />
      </Grid>
      <Grid className={styles.gridItem} xs={6} lg={6}>
        <TextField
          required
          sx={{ width: '99%' }}
          variant='filled'
          size='small'
          type='number'
          label={getTag('quantity')}
          inputRef={quantity}
        />
      </Grid>
      <Grid className={styles.gridItem} xs={6} lg={6}>
        <FormControlLabel control={<Checkbox checked={featured} onChange={handleFeatured} />} label={getTag('featured')} />
      </Grid>
      <Grid className={styles.gridPrice} xs={12} lg={12}>
        <Typography sx={{ fontWeight: 'bold', margin: '1vh 0' }}>{getTag('price')}</Typography>
        <FormControl sx={{ width: '48.5%', margin: '0 51.5% 1vh 0' }}>
          <InputLabel>produkt typ</InputLabel>
          <Select
            value={productType}
            variant='filled'
            size='small'
            onChange={(e) => setProductType(Number(e.target.value))}
          >
            {productTypes && productTypes.map(
              (e: any) => (
                <MenuItem value={e.id}>{e.name}</MenuItem>
              )
            )}
          </Select>
        </FormControl>
        {timeFrames && timeFrames.map(
          (e: any, i: number) => (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography sx={{ width: '49.5%', marginLeft: '0.75%' }}>
                {`${e.fromTime} - ${e.toTime} ${e.fullDay ? ` - ${getTag('fullDay')}` : ''}`}
              </Typography>
              <TextField
                required
                sx={{ width: '49.5%' }}
                defaultValue={e.price}
                variant='filled'
                size='small'
                type='number'
                label={getTag('price')}
                onChange={(event) => changePrice(i, event)}
              />
            </div>
          )
        )}
      </Grid>
      <Grid className={styles.gridItem} xs={12} lg={6}>
        <TextField
          required
          sx={{ width: '99%' }}
          multiline
          variant='filled'
          size='small'
          label={`${getTag('info')} Sv`}
          inputRef={infoSv}
        />
      </Grid>
      <Grid className={styles.gridItem} xs={12} lg={6}>
        <TextField
          required
          sx={{ width: '99%' }}
          multiline
          variant='filled'
          size='small'
          label={`${getTag('info')} Fi`}
          inputRef={infoFi}
        />
      </Grid>
      <Grid className={styles.gridItem} xs={12} lg={6}>
        <TextField
          required
          sx={{ width: '99%' }}
          multiline
          variant='filled'
          size='small'
          label={`${getTag('technicalDetails')} Sv`}
          inputRef={technicalSv}
        />
      </Grid>
      <Grid className={styles.gridItem} xs={12} lg={6}>
        <TextField
          required
          sx={{ width: '99%' }}
          multiline
          variant='filled'
          size='small'
          label={`${getTag('technicalDetails')} Fi`}
          inputRef={technicalFi}
        />
      </Grid>
      <Grid className={styles.gridItem} xs={12} lg={12}>
        <TextField
          sx={{ width: '100%' }}
          variant='filled'
          size='small'
          label={getTag('videoLink')}
          inputRef={videoLink}
        />
      </Grid>
      <Grid className={styles.gridItem} xs={12} lg={12}>
        <div className={styles.leftPictureDiv}>
          <Typography sx={{ fontWeight: 'bold' }}>{getTag('productPicture')}</Typography>
        </div>
        <div className={styles.rightPictureDiv}>
          <input
            style={{ float: 'right', padding: '0.4vw' }}
            type='file'
            name='productImage'
            onChange={(event: any) => { setImg(event.target.files[0]); setImgName(event.target.files[0].name); }}
          />
        </div>
      </Grid>
      <Grid className={styles.gridItem} xs={12} lg={12}>
        <div className={styles.leftPictureDiv}>
          <Typography sx={{ fontWeight: 'bold' }}>{getTag('productBackground')}</Typography>
        </div>
        <div className={styles.rightPictureDiv}>
          <input
            style={{ float: 'right', padding: '0.4vw' }}
            type='file'
            name='productBackground'
            onChange={(event: any) => { setBackground(event.target.files[0]); setBackgroundName(event.target.files[0].name); }}
          />
        </div>
      </Grid>
      <Grid className={styles.gridItem} xs={12} lg={12}>
        <Typography sx={{ fontWeight: 'bold' }}>{getTag('addonsOnlyBookedWithProduct')}</Typography>
      </Grid>
      {relations && relations.map(
        (e: any, i: number) => (
          <Grid className={styles.gridItem} xs={6} lg={6}>
            <FormControlLabel
              control={
                <Checkbox checked={e.linked} onChange={(event) => handleRelations(i, event)} />
              }
              label={locale === 'sv' ? e.nameSv : e.nameFi}
            />
          </Grid>
        )
      )}
      <Grid className={styles.gridSave} xs={12}>
        <Button type='submit' className={styles.saveButton} onClick={saveData}>
          {getTag('save')}
        </Button>
      </Grid>
    </Grid>
  );
}
