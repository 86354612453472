import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Grid,
  Typography,
  TextField
} from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { getBookings } from '../../../services/BookingService';
import { usePageContext } from '../../../Context';
import styles from './Bookings.module.css';
import { BookingList, BookingListItem } from '../../../types';

export default function Bookings() {
  const {
    getTag,
    snackBarEvent,
    setLoading
  } = usePageContext();
  const navigate = useNavigate();

  const [bookings, setBookings] = useState<BookingList>([]);
  const [viewBookings, setViewBookings] = useState<BookingList>([]);

  function getState(data: BookingListItem, inputValue: String, state: any = false) {
    for (const value of Object.values(data)) { // eslint-disable-line no-restricted-syntax
      if (typeof value === 'object' && value !== null && Object.keys(value).length > 0 && state === false) {
        state = getState(value, inputValue, state);// eslint-disable-line no-param-reassign
      } else if (state === false) {
        state = JSON.stringify(value).toLowerCase().includes(inputValue.toLowerCase());// eslint-disable-line no-param-reassign
      } else {
        return state;
      }
    }
    return state;
  }

  function search(searchInput: String) {
    setViewBookings(bookings.filter((element) => getState(element, searchInput)));
  }

  const fetchBookings = async () => {
    setLoading(true);
    try {
      const res = await getBookings();
      if (res?.length > 0) {
        res.sort((a: BookingListItem, b: BookingListItem) => {
          if (a.date.toString() > b.date.toString()) {
            return -1;
          }
          if (a.date.toString() < b.date.toString()) {
            return 1;
          }
          return 0;
        });
        setBookings(res);
        setViewBookings(res);
      }
    } catch (error) {
      snackBarEvent('error', 'Unable to fetch bookings');
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchBookings();
  }, []);

  return (
    <Grid className={styles.gridWrapper} container spacing={0}>
      <Grid className={styles.headerText} xs={7}>
        <Typography variant='h6' sx={{ fontWeight: 'bold' }}>
          {bookings.length ? getTag('bookings') : getTag('noBookings')}
        </Typography>
      </Grid>
      <Grid className={styles.headerText} xs={5}>
        <TextField
          className={styles.searchField}
          size='small'
          onChange={(e) => search(e.target.value)}
          placeholder={getTag('search')}
          variant='outlined'
        />
        <AddCircleIcon className={styles.addIcon} onClick={() => navigate('/dashboard/bookings/add')} />
      </Grid>
      {viewBookings.length && viewBookings.map((booking: BookingListItem) => (
        <Grid className={styles.productCont} container onClick={() => navigate(`/dashboard/bookings/${booking.uuid}`)}>
          <Grid className={styles.itemBox} xs={3}>
            {!booking.paid && (
              <div style={{ width: '96%' }}>
                <Typography sx={{ color: 'red' }}>
                  {getTag('unpaid')}
                </Typography>
              </div>
            )}
          </Grid>
          <Grid className={styles.itemBox} xs={6}>
            <Typography className={styles.itemText}>
              {`${booking.firstName} ${booking.lastName}`}
            </Typography>
          </Grid>
          <Grid className={styles.itemBox} xs={3}>
            <div style={{ width: '96%', textAlign: 'right' }}>
              <Typography>
                {booking.date.toString()}
              </Typography>
              <Typography>
                {`${booking.price}€`}
              </Typography>
            </div>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
}
