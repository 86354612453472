import {
  get,
  del
} from '../helpers/BackendService';

export function checkPayment(paymentIntent: string, locale: string) {
  return get(`prices/checkPayment/${paymentIntent}?locale=${locale}`);
}

export function deleteOngoing(uuid: string) {
  return del('bookings/ongoing', uuid);
}
