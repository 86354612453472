import React, { useState, useEffect } from 'react';
import styles from './Checkout.module.css';
import { checkPayment } from '../../../services/StripeService';
import { usePageContext } from '../../../Context';

export default function PaymentSuccess() {
  const [paymentIntentID, setPaymentIntentID] = useState<string | null>();
  const [message, setMessage] = useState<string | undefined>();
  const [emailText, setEmailText] = useState<string | undefined>();
  const { getTag, setLoading, locale } = usePageContext();

  const checkPaymentStatus = async () => {
    setLoading(true);
    localStorage.removeItem('cart');
    try {
      const res = await checkPayment(paymentIntentID!, locale);
      switch (res.status) {
      case 'succeeded':
        setMessage(getTag('paymentSucceded'));
        setEmailText(getTag('emailText'));
        setLoading(false);
        break;
      case 'processing':
        setMessage(res.status);
        setLoading(false);
        break;
      case 'requires_payment_method':
        setMessage(res.status);
        setLoading(false);
        break;
      default:
        setMessage(res.status);
        setLoading(false);
        break;
      }
    } catch (error) {
      setMessage(getTag('somethingwentwrong'));
      setLoading(false);
    }
  };

  const getPaymentIntent = () => {
    setPaymentIntentID(new URLSearchParams(window.location.search).get('payment_intent'));
  };

  useEffect(() => {
    if (!paymentIntentID) {
      getPaymentIntent();
    } else {
      checkPaymentStatus();
    }
  }, [getTag, paymentIntentID]);

  return (
    <div className={styles.statusWrapper}>
      <div className={styles.cardBox}>
        <h2>{message}</h2>
        <h3>{emailText}</h3>
      </div>
    </div>
  );
}
