import React from 'react';

export default function DefaultPage() {
  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
      }}
    >
      <h1>
        404
      </h1>
      <h3>
        Page not found
      </h3>
    </div>
  );
}
