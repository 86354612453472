import { Link, useLocation } from 'react-router-dom';
import React from 'react';
import {
  useMediaQuery,
  Box,
  Grid,
  useTheme,
  AppBar,
  Button
} from '@mui/material';
import Styles from './Navbar.module.css';
import DrawerComponent from './Drawer';
import LanguageMenu from './LanguageMenu';
import { usePageContext } from '../../Context';
import { Pages, NavbarPictures } from '../../types';

interface PropsInterface {
  pages?: Pages[];
  pictures?: NavbarPictures;
}

export default function Layout(props: PropsInterface) {
  const location = useLocation();
  const {
    pages,
    pictures
  } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const { getTag } = usePageContext();
  const pathName = location.pathname.split('/')[1];

  const navbarFontSize = 20;
  const navbarFontWeight = 700;

  return (
    <AppBar position='static'>
      <Grid
        container
        spacing={0}
        direction='column'
        justifyContent='center'
        className={Styles.navbar}
        sx={{ backgroundColor: 'primary.main' }}
      >
        <div className={Styles.leftSide}>
          <LanguageMenu color='#fff' navbarFontWeight={navbarFontWeight} navbarFontSize={navbarFontSize} />
        </div>
        <div className={Styles.mid}>
          <Box
            component='img'
            src={`data:image/svg;base64,${pictures?.logo}`}
            alt='logo'
            className={Styles.hyyreLogo}
          />
        </div>
        <div className={Styles.rightSide}>
          {
            // eslint-disable-next-line
            isMobile ? (
              <DrawerComponent navbarFontWeight={navbarFontWeight} navbarFontSize={navbarFontSize} pages={pages} />
            ) : (
              pathName === 'dashboard' ? (
                pages?.map((page) => (
                  <Button
                    key={page.name}
                    component={Link}
                    to={`/${page.path}`}
                    sx={{
                      fontWeight: 700,
                      fontSize: 20,
                      color: 'white',
                      flexGrow: 1,
                      display: 'block'
                    }}
                  >
                    {getTag(page.name)}
                  </Button>
                ))
              ) : (
                pages?.map((page) => (
                  <Button
                    key={page.name}
                    onClick={() => window.location.assign(page.path)}
                    sx={{
                      fontWeight: 700,
                      fontSize: 20,
                      color: 'white',
                      flexGrow: 1,
                      display: 'block'
                    }}
                  >
                    {getTag(page.name)}
                  </Button>
                ))
              )
            )
          }
        </div>
      </Grid>
    </AppBar>
  );
}
