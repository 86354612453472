import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Grid,
  Typography
} from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import styles from './Addons.module.css';
import { usePageContext } from '../../../Context';
import { getAddons } from '../../../services/AddonService';
import { AddonsList } from '../../../types';

export default function Addons() {
  const {
    setLoading,
    snackBarEvent,
    getTag,
    locale
  } = usePageContext();
  const [items, setItems] = useState<AddonsList[] | undefined>();
  const navigate = useNavigate();

  const fetchData = async () => {
    setLoading(true);
    try {
      const data = await getAddons();
      setItems(data);
    } catch (error) {
      snackBarEvent('error', getTag('unabletofetchproducts'));
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Grid className={styles.gridWrapper} container spacing={0}>
      <Grid className={styles.addButton} item xs={12}>
        <AddCircleIcon className={styles.addIcon} onClick={() => navigate('/dashboard/addons/add')} />
      </Grid>
      {items?.map((p) => (
        <Grid className={styles.productCont} container onClick={() => navigate(`/dashboard/addons/${p.uuid}`)}>
          <Grid className={styles.pictureBox} xs={3}>
            <img className={styles.picture} src={`data:image/png;base64,${p.addonPicture}`} alt='product' />
          </Grid>
          <Grid className={styles.itemBox} xs={9}>
            <Typography className={styles.itemText}>
              {locale === 'sv' ? p.nameSv : p.nameFi}
            </Typography>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
}
