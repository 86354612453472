import React, { FormEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Card } from '@mui/material';
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import styles from './Checkout.module.css';
import { usePageContext } from '../../../Context';
import { frontendURL } from '../../../helpers/Config';

export default function Checkout() {
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const {
    getTag,
    setLoading
  } = usePageContext();
  const navigate = useNavigate();
  const paymentHandler = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!stripe || !elements) { return; }

    setLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${frontendURL}/hyyre/paymentstatus`
      }
    });
    if (error.type === 'card_error' || error.type === 'validation_error') {
      setErrorMessage(error.message);
    } else {
      setErrorMessage('An unexpected error occurred.');
    }

    setLoading(false);
  };

  return (
    <div className={styles.wrapper}>
      <Card className={styles.cardBox}>
        <form className={styles.formBox} onSubmit={paymentHandler}>
          <PaymentElement />
          {errorMessage && (
            <div className='text-pink-500 p-2 rounded-md mt-2 bold bg-pink-100'>
              {errorMessage}
            </div>
          )}
          <Button onClick={() => navigate('/hyyre/cart')} className={styles.backButton}>
            {getTag('back')}
          </Button>
          <Button type='submit' className={styles.payButton}>
            {getTag('pay')}
          </Button>
        </form>
      </Card>
    </div>
  );
}
