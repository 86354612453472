import React from 'react';
import ReactDOM from 'react-dom/client';
import { ErrorBoundary } from '@highlight-run/react';
import './index.css';
import ContextProvider from './ContextProvider';
import env from './helpers/env.json';
import { init } from './services/HighlightService';

init();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  env.env === 'production' ? (
    <ErrorBoundary>
      <ContextProvider />
    </ErrorBoundary>
  ) : <ContextProvider />
);
