import React, { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Navbar from '../navbar/Navbar';
import Footer from '../footer/Footer';
import { usePageContext } from '../../Context';
import { Pages, NavbarPictures } from '../../types';
import { getPages } from '../../services/PageService';
import { getNavbarPictures } from '../../services/PictureService';

export default function BookingLayout() {
  const { getTag, setLoading, snackBarEvent } = usePageContext();
  const [pages, setPages] = useState<Pages[]>();
  const [pictures, setPictures] = useState<NavbarPictures>();
  const location = useLocation();

  const fetchData = async () => {
    setLoading(true);
    const pathName = location.pathname.split('/')[1];
    try {
      const pics = await getNavbarPictures();
      setPictures(pics);
    } catch (error) {
      snackBarEvent('error', getTag('unabletofetchpictures'));
    }
    try {
      const page = await getPages(pathName);
      setPages(page);
    } catch (error) {
      snackBarEvent('error', getTag('unabletofetchpages'));
    }
    document.title = 'Hyyre';
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Navbar pages={pages} pictures={pictures} />
      <Outlet />
      <Footer />
    </>
  );
}
