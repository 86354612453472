import {
  get,
  post,
  put,
  del
} from '../helpers/BackendService';
import { DashboardRelations, AddonsWithPicture, AddonsList } from '../types';

export function getAddons(): Promise<AddonsList[]> {
  return get('addons');
}

export function getAddonsForProduct(uuid: string): Promise<AddonsWithPicture[]> {
  return get(`addons/forProduct/${uuid}`);
}

export function getAddon(uuid: string): Promise<AddonsWithPicture> {
  return get(`addons/${uuid}`);
}

export function getDashboardRelations(uuid: string): Promise<DashboardRelations[]> {
  return get(`addons/dashboardRelations/${uuid}`);
}

export function addAddon(data: Object) {
  return post('addons', data);
}

export function getAvailableAddons(data: Object) {
  return post('addons/getForBooking', data);
}

export function uploadAddonPicture(data: object) {
  return post('addonPictures', data);
}

export function changeAddon(uuid: string, data: object) {
  return put(`addons/${uuid}`, data);
}

export function changeAddonPicture(data: object) {
  return put('addonPictures', data);
}

export function deleteAddon(uuid: string) {
  return del('addons', uuid);
}
