import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Button,
  Grid,
  Typography,
  Modal,
  Box
} from '@mui/material';
import { usePageContext } from '../../../Context';
import styles from './Booking.module.css';
import { deleteBooking, getBooking } from '../../../services/BookingService';
import { BookingType } from '../../../types';

export default function Booking() {
  const navigate = useNavigate();
  const {
    getTag,
    snackBarEvent,
    setLoading,
    locale
  } = usePageContext();

  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  const { uuid } = useParams();

  const [booking, setBooking] = useState<BookingType>();

  const fetchBookings = async () => {
    setLoading(true);
    try {
      if (uuid) {
        const res = await getBooking(uuid);
        setBooking(res);
      }
    } catch (error) {
      snackBarEvent('error', 'Unable to fetch bookings');
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchBookings();
  }, []);

  const removeBooking = async () => {
    try {
      setOpen(false);
      if (booking) {
        await deleteBooking(booking.uuid);
        navigate('/dashboard/bookings/');
      }
    } catch (error) {
      snackBarEvent('error', getTag('somethingWentWrong'));
    }
  };

  return (
    <div>
      {(booking && (
        <>
          <Grid className={styles.gridWrapper} container spacing={0}>
            <Grid className={styles.namewrapper} xs={12}>
              <div className={styles.nameBox}>
                <Typography variant='h5' className={styles.nameText}>
                  {`${booking.firstName} ${booking.lastName}`}
                </Typography>
                <Typography>
                  {booking.phone}
                </Typography>
                <Typography>
                  {booking.email}
                </Typography>
              </div>
              <div className={styles.dateBox}>
                <Typography sx={{ fontWeight: 'bold' }}>
                  {booking.date.from}
                </Typography>
                <Typography sx={{ fontWeight: 'bold' }}>
                  {booking.date.to ? booking.date.to : booking.date.timeFrame}
                </Typography>
                <Typography sx={{ fontWeight: 'bold' }}>
                  {booking.price}
                  €
                </Typography>
                {!booking.paid && (
                  <Typography sx={{ fontWeight: 'bold', color: 'red' }}>
                    {getTag('unpaid')}
                  </Typography>
                )}
              </div>
            </Grid>
            {booking.savedBookedProducts.length > 0 && (
              <Grid className={styles.itemBox} xs={12}>
                <Typography variant='h6' className={styles.h6Text}>
                  {`${getTag('products')}: `}
                </Typography>
              </Grid>
            )}
            {booking.savedBookedProducts && booking.savedBookedProducts.map((product) => (
              <Grid className={styles.itemBox} xs={12}>
                <Typography className={styles.itemText}>
                  {`${product.manufacturer} ${product.model}`}
                </Typography>
              </Grid>
            ))}
            {booking.addons.length > 0 && (
              <Grid className={styles.itemBox} xs={12}>
                <Typography variant='h6' className={styles.h6Text}>
                  {`${getTag('addons')}: `}
                </Typography>
              </Grid>
            )}
            {booking.addons.map((addon) => (
              <Grid className={styles.itemBox} xs={12}>
                <Typography className={styles.itemText}>
                  {locale === 'sv' ? addon.nameSv : addon.nameFi}
                </Typography>
              </Grid>
            ))}
          </Grid>
          <div className={styles.buttonDiv}>
            <Button className={styles.backButton} onClick={() => navigate('/dashboard/bookings/')}>
              {getTag('back')}
            </Button>
            <Button
              className={styles.changeButton}
              onClick={() => navigate(`/dashboard/bookings/edit/${uuid}`)}
            >
              {getTag('changeDate')}
            </Button>
            <Button className={styles.delButton} onClick={() => setOpen(true)}>
              {getTag('delete')}
            </Button>
          </div>
          <Modal
            open={open}
            onClose={handleClose}
          >
            <Box className={styles.modal}>
              <div className={styles.modalText}>

                <Typography id='modal-modal-title' variant='h6' component='h2'>
                  {getTag('removeBooking')}
                </Typography>
                <Typography sx={{ mt: 2 }}>
                  {getTag('removeBookingVarning')}
                </Typography>
              </div>
              <Button className={styles.delButton} onClick={() => removeBooking()}>
                {getTag('delete')}
              </Button>
              <Button className={styles.backButton} onClick={() => setOpen(false)}>
                {getTag('back')}
              </Button>
            </Box>
          </Modal>
        </>
      ))}
    </div>
  );
}
