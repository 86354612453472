import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Grid,
  Button,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormControlLabel,
  Checkbox
} from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import MuiPhoneNumber from 'mui-phone-number';
import { usePageContext } from '../../../Context';
import styles from './AddBooking.module.css';
import DateTimePickers from '../../products/dateTimePickers/DateTimePickers';
import ProductView from '../../products/productView/ProductView';
import AddonView from '../../products/addonView/AddonView';
import { getDisabledDates, getAvailableProducts } from '../../../services/ProductService';
import { getAvailableAddons } from '../../../services/AddonService';
import { bookingProducts, AddonsWithPicture } from '../../../types';
import { addAdminBooking } from '../../../services/BookingService';
import addOnePastNoon from '../../../helpers/Helpers';

type HighlightedDay = {
  date: Dayjs;
  styles: React.CSSProperties;
};

export default function AddBooking() {
  const [fromCurrentValue, setFromCurrentValue] = useState<Dayjs | null>(addOnePastNoon);
  const [toCurrentValue, setToCurrentValue] = useState<Dayjs | null>(dayjs().add(2, 'day'));
  const [selectedHours, setSelectedHours] = useState<number | undefined>(0);
  const [highlightedDays, setHighlightedDays] = useState<HighlightedDay[]>([]);
  const [daysAmount, setDaysAmount] = useState<number>(0);
  const [montView, setMontView] = useState<Dayjs | null>(addOnePastNoon);
  const [products, setProducts] = useState<bookingProducts[]>([]);
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [productsAmount, setProductsAmount] = useState<{ [key: number]: { 'uuid': string, 'number': number } }>();
  const [addons, setAddons] = useState<AddonsWithPicture[]>([]);
  const [addonsAmount, setAddonsAmount] = useState<{ [key: number]: number }>();
  const [relatedAddons, setRelatedAddons] = useState<AddonsWithPicture[]>([]);
  const [relatedAddonsAmount, setRelatedAddonsAmount] = useState<{ [key: number]: number }>();
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [language, setLanguage] = useState<string>('sv');
  const [sendEmail, setSendEmail] = useState<boolean>(false);
  const {
    setLoading,
    snackBarEvent,
    getTag
  } = usePageContext();
  const navigate = useNavigate();

  const formValidation: { [key: string]: boolean } = {
    firstName: firstName.length > 0,
    lastName: lastName.length > 0,
    email: email.length > 0,
    phoneNumber: phoneNumber.length === 17
  };

  const formValid = () => Object.keys(formValidation).every((e) => formValidation[e]);

  const fetchHighlightedDays = async () => {
    if (montView) {
      let start = (montView.get('month') + 1).toString();
      let end = (montView.get('month') + 3).toString();
      if (start.length === 1) { start = `0${start}`; }
      if (end.length === 1) { end = `0${end}`; }
      try {
        const data: [{ date: string, color: string }] = await getDisabledDates(
          `${montView.get('year').toString()}-${start}-01,${montView.get('year')}-${end}-01`
        );
        const modifiedData: HighlightedDay[] = [];
        data.forEach((e: { date: string, color: string }) => {
          modifiedData.push({
            date: dayjs(e.date),
            styles: { backgroundColor: e.color }
          });
        });
        setHighlightedDays(modifiedData);
      } catch (error) {
        snackBarEvent('error', getTag('unabletofetchproducts'));
      }
    }
  };

  const searchProducts = async () => {
    setLoading(true);
    const tmpJson: { [key: number]: { 'uuid': string, 'number': number } } = {};
    const from = fromCurrentValue!.format().split('T')[0];
    const to = daysAmount ? toCurrentValue!.format().split('T')[0] : selectedHours!;
    try {
      const data = await getAvailableProducts(from, to);
      data.forEach((e: bookingProducts) => {
        tmpJson[e.id] = { uuid: e.uuid, number: 0 };
      });
      setProducts(data);
      setProductsAmount(tmpJson);
    } catch (error) {
      snackBarEvent('error', getTag('unabletofetchproducts'));
    }
    setLoading(false);
  };

  const updateAddons = async (connectedToProduct: boolean) => {
    const tmpJson: { [key: number]: number } = {};
    const from = fromCurrentValue!.format().split('T')[0];
    const to = daysAmount ? toCurrentValue!.format().split('T')[0] : selectedHours!;
    const dateProduct = {
      from,
      to,
      productsAmount,
      connectedToProduct
    };
    try {
      const data = await getAvailableAddons(dateProduct);
      data.data.forEach((e: AddonsWithPicture) => {
        tmpJson[e.id] = 0;
      });
      if (connectedToProduct) {
        setRelatedAddons(data.data);
        setRelatedAddonsAmount(tmpJson);
      } else {
        setAddons(data.data);
        setAddonsAmount(tmpJson);
      }
    } catch (error) {
      snackBarEvent('error', getTag('unabletofetchproducts'));
    }
  };

  const saveData = async () => {
    const bookedProducts: Array<{ id: string, amount: number }> = [];
    const bookedAddons: Array<{ id: string, amount: number }> = [];
    if (productsAmount && addonsAmount && relatedAddonsAmount) {
      // eslint-disable-next-line
      for (const key in productsAmount) {
        if (productsAmount[key].number > 0) {
          bookedProducts.push({
            id: key,
            amount: productsAmount[key].number
          });
        }
      }
      // eslint-disable-next-line
      for (const key in addonsAmount) {
        if (addonsAmount[key] > 0) {
          bookedAddons.push({
            id: key,
            amount: addonsAmount[key]
          });
        }
      }
      // eslint-disable-next-line
      for (const key in relatedAddonsAmount) {
        if (relatedAddonsAmount[key] > 0) {
          bookedAddons.push({
            id: key,
            amount: relatedAddonsAmount[key]
          });
        }
      }
      const data = {
        firstName,
        lastName,
        email,
        phoneNumber,
        bookingInfo: {
          from: fromCurrentValue,
          to: daysAmount ? toCurrentValue : selectedHours,
          products: bookedProducts,
          addons: bookedAddons
        },
        locale: language,
        sendEmail
      };
      try {
        await addAdminBooking(data);
        navigate('/dashboard/bookings');
      } catch (error) {
        snackBarEvent('error', getTag('somethingWentWrong'));
      }
    } else {
      snackBarEvent('error', getTag('setAllFields'));
    }
  };

  useEffect(() => {
    fetchHighlightedDays();
  }, [montView, daysAmount]);

  useEffect(() => {
    if (toCurrentValue && selectedHours) {
      searchProducts();
      updateAddons(false);
    }
  }, [fromCurrentValue, toCurrentValue, selectedHours, daysAmount]);

  useEffect(() => {
    if (daysAmount) setToCurrentValue(dayjs(fromCurrentValue).add(1, 'day'));
  }, [fromCurrentValue]);

  useEffect(() => {
    updateAddons(true);
  }, [productsAmount]);

  useEffect(() => {
    let tmpPrice = 0;
    if (productsAmount && products) {
      products.forEach((e) => {
        tmpPrice += e.price * productsAmount[e.id].number;
      });
    }
    if (addonsAmount && addons) {
      addons.forEach((e) => {
        tmpPrice += e.price * addonsAmount[e.id];
      });
    }
    setTotalPrice(tmpPrice);
  }, [productsAmount, addonsAmount]);

  return (
    <Grid className={styles.gridWrapper} container spacing={0}>
      <Grid className={styles.itemTop} item xs={12}>
        <DateTimePickers
          fromCurrentValue={fromCurrentValue}
          setFromCurrentValue={setFromCurrentValue}
          toCurrentValue={toCurrentValue}
          setToCurrentValue={setToCurrentValue}
          selectedHours={selectedHours}
          setSelectedHours={setSelectedHours}
          highlightedDays={highlightedDays}
          daysAmount={daysAmount}
          setDaysAmount={setDaysAmount}
          setMontView={setMontView}
        />
      </Grid>
      {(products && productsAmount) && (
        <Grid className={styles.itemLeft} item xs={12} lg={6}>
          <ProductView
            products={products}
            setProductsAmount={setProductsAmount}
            productsAmount={productsAmount}
          />
        </Grid>
      )}
      {(addons.length > 0 && addonsAmount) && (
        <Grid className={styles.itemRight} item xs={12} lg={6}>
          <AddonView
            addons={addons}
            relatedAddons={relatedAddons}
            addonsAmount={addonsAmount}
            setAddonsAmount={setAddonsAmount}
            relatedAddonsAmount={relatedAddonsAmount}
            setRelatedAddonsAmount={setRelatedAddonsAmount}
          />
        </Grid>
      )}
      <Grid className={styles.inputCont} item xs={12}>
        <TextField
          required
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          error={!formValidation.firstName}
          className={styles.textField}
          multiline
          variant='filled'
          size='small'
          label={`${getTag('firstName')}`}
        />
        <TextField
          required
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          error={!formValidation.lastName}
          className={styles.textField}
          multiline
          variant='filled'
          size='small'
          label={`${getTag('lastName')}`}
        />
        <TextField
          required
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          error={!formValidation.email}
          className={styles.textField}
          multiline
          variant='filled'
          size='small'
          label='Email'
        />
        <MuiPhoneNumber
          onChange={(e) => { setPhoneNumber(e.toString()); }}
          variant='filled'
          label='Telefonnummer'
          value={phoneNumber}
          required
          className={styles.textField}
          disableDropdown
          defaultCountry='fi'
          error={!formValidation.phoneNumber}
        />
      </Grid>
      <Grid className={styles.emailCont} item xs={12}>
        <FormControlLabel
          className={styles.sendEmailCheckbox}
          control={
            <Checkbox checked={sendEmail} onChange={() => setSendEmail(!sendEmail)} />
          }
          label={getTag('sendEmail')}
        />
        {sendEmail && (
          <FormControl className={styles.sendEmailCheckbox}>
            <InputLabel>{getTag('language')}</InputLabel>
            <Select
              value={language}
              label={getTag('language')}
              onChange={(e) => setLanguage(e.target.value)}
            >
              <MenuItem value='sv'>sv</MenuItem>
              <MenuItem value='fi'>fi</MenuItem>
            </Select>
          </FormControl>
        )}
      </Grid>
      <Grid className={styles.itemBottom} item xs={12}>
        <div style={{ display: 'flex', marginLeft: '2vw' }}>
          <Typography variant='h6' sx={{ fontWeight: 'bold', margin: '2vh 0' }}>{`${getTag('price')}`}</Typography>
          <Typography variant='h6' sx={{ color: 'green', fontWeight: 'bold', margin: '2vh 0' }}>{`: ${totalPrice}`}</Typography>
          <Typography variant='h6' sx={{ fontWeight: 'bold', margin: '2vh 0' }}>€</Typography>
        </div>
        <Button
          className={styles.saveButton}
          sx={totalPrice === 0 || !formValid() ? { backgroundColor: 'lightgrey' } : { backgroundColor: '#bad85f' }}
          disabled={totalPrice === 0 || !formValid()}
          onClick={saveData}
        >
          {getTag('save')}
        </Button>
      </Grid>
    </Grid>
  );
}
