import { get } from '../helpers/BackendService';
import { Pages } from '../types';

export function getPages(pathName: string): Promise<Pages[]> {
  return get(`pages/regular/${pathName}`);
}

export function getDashboardPages(): Promise<Pages[]> {
  return get('pages/dashboard');
}
