import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import Styles from './Footer.module.css';
import { usePageContext } from '../../Context';
import { FooterPictures } from '../../types';
import { getFooterPictures } from '../../services/PictureService';

export default function Home() {
  const { getTag, setLoading, snackBarEvent } = usePageContext();
  const [pictures, setPictures] = useState<FooterPictures>();

  const fetchPictures = async () => {
    setLoading(true);
    try {
      const data = await getFooterPictures();
      setPictures(data);
    } catch (error) {
      snackBarEvent('error', getTag('unabletofetchpictures'));
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchPictures();
  }, []);

  return (
    <>
      <img src={`data:image/svg;base64,${pictures?.footerImage}`} alt='footer' className={Styles.footerImage} />
      <div className={Styles.footerDiv}>
        <div className={Styles.leftDiv}>
          <Typography variant='h6'>
            {getTag('hyyre')}
          </Typography>
          <Typography
            variant='h6'
            sx={{ cursor: 'pointer', color: 'white', textDecoration: 'underline' }}
            onClick={() => window.location.assign('https://hyyre.fi/rental-conditions')}
          >
            {getTag('rentalconditions')}
          </Typography>
        </div>
        <div className={Styles.rightDiv}>
          <Typography variant='h6'>
            {getTag('tyrecenter')}
          </Typography>
          <Typography variant='h6'>
            {getTag('adress')}
          </Typography>
          <Typography variant='h6'>
            {getTag('postal')}
          </Typography>
        </div>
      </div>
    </>
  );
}
