import React, { useState, Dispatch, useEffect } from 'react';
import {
  Grid,
  Select,
  MenuItem,
  TextField,
  Typography
} from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import styles from './DateTimePickers.module.css';
import { usePageContext } from '../../../Context';
import { getBookingTimeframes } from '../../../services/ProductService';
import 'dayjs/locale/sv';
import 'dayjs/locale/fi';
import { timeFrame } from '../../../types';

type HighlightedDay = {
  date: Dayjs;
  styles: React.CSSProperties;
};

type propsInterface = {
  fromCurrentValue: Dayjs | null;
  setFromCurrentValue: Dispatch<Dayjs | null>;
  setMontView: Dispatch<Dayjs | null>;
  toCurrentValue: Dayjs | null;
  setToCurrentValue: Dispatch<Dayjs | null>;
  selectedHours: number | undefined;
  setSelectedHours: Dispatch<number | undefined>;
  highlightedDays: HighlightedDay[];
  daysAmount: number;
  setDaysAmount: Dispatch<number>;
};

export default function DateTimePickers(props: propsInterface) {
  const {
    highlightedDays,
    fromCurrentValue,
    setFromCurrentValue,
    selectedHours,
    setSelectedHours,
    setToCurrentValue,
    toCurrentValue,
    daysAmount,
    setDaysAmount,
    setMontView
  } = props;
  const { getTag, locale, snackBarEvent } = usePageContext();
  const [timeFrames, setTimeFrames] = useState<timeFrame[]>([]);
  const productType = 1;

  const renderWeekPickerDay = (
    date: Dayjs,
    selectedDates: Array<Dayjs | null>,
    pickersDayProps: PickersDayProps<Dayjs>
  ) => {
    const matchedStyles = highlightedDays.reduce((a, v) => (dayjs(date).isSame(v.date) ? v.styles : a), {});

    return (
      <PickersDay
        {...pickersDayProps}
        sx={{ backgroundColor: '#bad85f', ...matchedStyles }}
      />
    );
  };

  const fetchTimeframes = async (selectedDate: Dayjs) => {
    try {
      const data = await getBookingTimeframes(productType, selectedDate);
      setSelectedHours(data[0].id);
      setTimeFrames(data);
    } catch (error) {
      snackBarEvent('error', getTag('somethingWentWrong'));
    }
  };

  const checkDaysAmount = (dAmount: number) => ((dAmount || dayjs().hour() >= 12) ? dayjs().add(1, 'day') : dayjs());

  useEffect(() => {
    if (daysAmount === 0) fetchTimeframes(fromCurrentValue!);
  }, [daysAmount, fromCurrentValue]);

  return (
    <Grid className={styles.dateWrapper} container spacing={0}>
      <Grid className={styles.dateItem} item xs={12} lg={4}>
        <Select
          className={styles.calendarsPadding}
          value={daysAmount}
          onChange={(e) => {
            if (daysAmount !== Number(e.target?.value)) {
              setDaysAmount(Number(e.target?.value));
              setSelectedHours(0);
              setFromCurrentValue(checkDaysAmount(Number(e.target?.value)));
              setToCurrentValue(dayjs().add(2, 'day'));
              setMontView(checkDaysAmount(Number(e.target?.value)));
            }
          }}
        >
          <MenuItem value='0'>{getTag('oneDay')}</MenuItem>
          <MenuItem value='1'>{getTag('multipleDays')}</MenuItem>
        </Select>
      </Grid>
      <Grid className={styles.dateItem} item xs={12} lg={4}>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
          <DatePicker
            views={['day']}
            label={daysAmount ? getTag('startDate') : getTag('date')}
            disablePast
            renderDay={renderWeekPickerDay}
            value={fromCurrentValue}
            minDate={checkDaysAmount(daysAmount)}
            maxDate={dayjs(new Date().setFullYear(new Date().getFullYear() + 1))}
            disableHighlightToday
            onMonthChange={(e) => setMontView(e)}
            className={styles.calendarsPadding}
            onChange={(newValue) => {
              setFromCurrentValue(newValue);
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      </Grid>
      <Grid className={styles.dateItem} item xs={12} lg={4}>
        {daysAmount ? (
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
            <DatePicker
              views={['day']}
              label={daysAmount ? getTag('endDate') : getTag('date')}
              disablePast
              renderDay={renderWeekPickerDay}
              value={toCurrentValue}
              minDate={fromCurrentValue?.add(1, 'day')}
              maxDate={fromCurrentValue?.add(1, 'month')}
              disableHighlightToday
              className={styles.calendarsPadding}
              onChange={(newValue) => {
                setSelectedHours(0);
                setToCurrentValue(newValue);
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        ) : (
          <Select
            className={styles.calendarsPadding}
            value={selectedHours}
            label={getTag('Tid')}
            onChange={(e) => {
              setSelectedHours(Number(e.target?.value));
            }}
          >
            {timeFrames.map(
              (e: { id: number, fromTime: string, toTime: string }) => (
                <MenuItem value={e.id}>{`${e.fromTime} - ${e.toTime}`}</MenuItem>
              )
            )}
          </Select>
        )}
      </Grid>
      <Grid className={styles.dateItem} item xs={12} lg={4}>
        {daysAmount ? (
          <>
            <Typography>
              {`${dayjs(fromCurrentValue).format('DD-MM-YYYY')} 10:00`}
            </Typography>
            <ArrowForwardIcon />
            <Typography>
              {`${dayjs(dayjs(toCurrentValue).add(1, 'day')).format('DD-MM-YYYY')} 08:00`}
            </Typography>
          </>
        ) : null}
      </Grid>
      <Grid className={styles.colorGrid} item xs={12} lg={8}>
        <div style={{ display: 'flex' }}>
          <FiberManualRecordIcon sx={{ color: '#bad85f' }} />
          <Typography>{`: ${getTag('allAvailable')}`}</Typography>
        </div>
        <div style={{ display: 'flex' }}>
          <FiberManualRecordIcon sx={{ color: 'yellow' }} />
          <Typography>{`: ${getTag('limitedAvailability')}`}</Typography>
        </div>
        <div style={{ display: 'flex' }}>
          <FiberManualRecordIcon sx={{ color: 'red' }} />
          <Typography>{`: ${getTag('allBooked')}`}</Typography>
        </div>
      </Grid>
    </Grid>
  );
}
