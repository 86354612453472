import React, { Dispatch } from 'react';
import {
  Grid,
  Select,
  MenuItem,
  Typography
} from '@mui/material';
import styles from './ProductView.module.css';
import { usePageContext } from '../../../Context';
import { bookingProducts } from '../../../types';

type propsInterface = {
  setProductsAmount: Dispatch<{ [key: number]: { 'uuid': string, 'number': number } } | undefined>;
  products: bookingProducts[];
  productsAmount: { [key: number]: { 'uuid': string, 'number': number } } | undefined;
}

export default function productView(props: propsInterface) {
  const {
    products,
    productsAmount,
    setProductsAmount
  } = props;
  const {
    getTag
  } = usePageContext();

  const amount = (e: number) => {
    const rows = [];
    for (let i = 0; i <= e; i++) {
      rows.push(<MenuItem value={i}>{i}</MenuItem>);
    }
    return rows;
  };

  const changeProductAmount = (id: number, event: number) => {
    if (!productsAmount) return;
    const newJson: { [key: number]: { 'uuid': string, 'number': number } } | undefined = { ...productsAmount };
    newJson[id].number = event;
    setProductsAmount(newJson);
  };

  return (
    <Grid className={styles.gridWrapper} container spacing={0}>
      <Grid xs={12}>
        <Typography variant='h6' sx={{ fontWeight: 'bold' }}>{getTag('products')}</Typography>
      </Grid>
      {productsAmount && products?.map((p) => (
        <Grid className={styles.productCont} container>
          <Grid className={styles.pictureBox} xs={3}>
            <img className={styles.featuredPicture} src={`data:image/png;base64,${p.featuredPicture}`} alt='product' />
          </Grid>
          <Grid className={styles.itemBox} xs={6}>
            <div>
              <Typography className={styles.itemText}>
                {p.manufacturer}
              </Typography>
              <Typography className={styles.itemText}>
                {p.model}
              </Typography>
              <Typography sx={{ textAlign: 'center' }}>
                {`${p.price}€/${getTag('pcs')}`}
              </Typography>
            </div>
          </Grid>
          <Grid className={styles.itemBox} xs={3}>
            {p.quantity === 0 ? (
              <Typography className={styles.itemText} sx={{ color: 'red' }}>
                {getTag('booked')}
              </Typography>
            ) : (
              <Select
                id={p.id.toString()}
                className={styles.calendarsPadding}
                value={productsAmount[p.id].number}
                label={getTag('Tid')}
                onChange={(e) => changeProductAmount(p.id, Number(e.target.value))}
              >
                {amount(p.quantity)}
              </Select>
            )}
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
}
