import { Dayjs } from 'dayjs';
import {
  get,
  post,
  put,
  del
} from '../helpers/BackendService';
import {
  AllProducts,
  Product,
  bookingProducts,
  timeFrame,
  ProductTypes
} from '../types';

export function getProduct(uuid: string | undefined): Promise<Product> {
  if (uuid) {
    return get(`products/${uuid}`);
  }
  throw new Error('No uuid when fetching product');
}

export function getProducts(): Promise<AllProducts[]> {
  return get('products');
}

export function getAvailableProducts(from: string, to: string | number): Promise<bookingProducts[]> {
  return get(`products/available/?from=${from}&to=${to}`);
}

export function getProductTypes(): Promise<ProductTypes[]> {
  return get('products/productTypes');
}

export function getTimeFrames(productType: number |undefined): Promise<timeFrame[]> {
  return get(`products/timeFrames/${productType}`);
}

export function getBookingTimeframes(productType: number |undefined, selectedDate: Dayjs): Promise<timeFrame[]> {
  const date = `${selectedDate.date()}.${selectedDate.month()}`;
  return get(`products/BookingTimeFrames/${productType}?selectedDate=${date}`);
}

export function getDisabledDates(dates: string): Promise<[{ date: string, color: string }]> {
  return get(`bookings/disabledDates/?dates=${dates}`);
}

export function checkDateChange(uuid: string, from: string, to: string | number): Promise<boolean> {
  return get(`bookings/checkDateChange/${uuid}?dates=${from},${to}`);
}

export function saveDateChange(uuid: string, from: string, to: string | number) {
  return put(`bookings/saveDateChange/${uuid}?dates=${from},${to}`);
}

export function getCartItems(cartItems: string) {
  return post('products/cartDisplayData/', cartItems);
}

export function getProductSpeces(id: string | undefined) {
  if (id) {
    return get(`markdowntext/productSpecs/${id}`);
  }
  return '';
}

export function addProduct(data: object) {
  return post('products', data);
}

export function changeProduct(uuid: string | undefined, data: object) {
  return put(`products/${uuid}`, data);
}

export function deleteProduct(uuid: string) {
  return del('products', uuid);
}

export function addPrice(uuid: string, data: object) {
  return post(`price/${uuid}`, data);
}
