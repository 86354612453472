import React, { useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { usePageContext } from '../Context';
import styles from './Epassi.module.css';

type EpassiRedirect = {
  stamp?: string;
  site?: string;
  amount?: string;
  rejectUrl?: string;
  cancelUrl?: string;
  returnUrl?: string;
  mac?: string;
  redirect: boolean;
};

// eslint-disable-next-line no-unused-vars
export default function EPassiTest(state: EpassiRedirect) {
  const location = useLocation();
  const { getTag } = usePageContext();
  const navigate = useNavigate();
  const formRef = useRef(null);
  const {
    stamp,
    site,
    amount,
    rejectUrl,
    cancelUrl,
    returnUrl,
    mac,
    redirect
  } = location.state!;

  useEffect(() => {
    if (redirect) {
      navigate('/hyyre/');
    }
    // @ts-ignore
    formRef.current.submit();
  }, []);
  return (
    <div className={styles.wrapper}>
      <div className={styles.cardBox}>
        <h4>{getTag('epassiRedirectInfo')}</h4>
        <h4>{getTag('epassiButtonInfo')}</h4>
        <form
          ref={formRef}
          method='post'
          action='https://services.epassi.fi/e_payments/v2'
          className={styles.formWrapper}
        >
          <input type='hidden' name='STAMP' value={stamp} />
          <input type='hidden' name='SITE' value={site} />
          <input type='hidden' name='AMOUNT' value={amount} />
          <input type='hidden' name='REJECT' value={rejectUrl} />
          <input type='hidden' name='CANCEL' value={cancelUrl} />
          <input type='hidden' name='RETURN' value={returnUrl} />
          <input type='hidden' name='MAC' value={mac} />
          <input
            type='submit'
            className={styles.button}
            value={getTag('continueToEpassi')}
          />
        </form>
      </div>
    </div>
  );
}
