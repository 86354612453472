import React from 'react';
import { useParams } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { stripePublikKey, stripeConnectedAccount } from '../../../helpers/Config';
import Checkout from './Checkout';
import { usePageContext } from '../../../Context';

export type StripeTypes = {
  clientSecret: string;
  appearance: {
    theme: 'stripe',
    variables: {
      colorPrimary: string
    }
  },
  locale: any
};

export default function CheckoutWrapper() {
  const { locale } = usePageContext();
  const { clientSecret } = useParams();

  const options: StripeTypes = {
    // pass the client secret
    clientSecret: clientSecret!,
    // Fully customizable with appearance API.
    appearance: {
      theme: 'stripe',
      variables: {
        colorPrimary: '#008b8b'
      }
    },
    locale
  };

  const stripePromise = loadStripe(stripePublikKey, {
    stripeAccount: stripeConnectedAccount
  });

  return (
    clientSecret ? (
      <Elements options={options} stripe={stripePromise}>
        <Checkout />
      </Elements>
    ) : (null)
  );
}
